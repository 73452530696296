import { gql } from '../../graphql';
import { ACCOUNTS_SETTING_FILTER_FIELDS } from '../../graphql/fragments/user/accountsetting';
import { SELECTED_USER_FILTERS, USER_ACCOUNTS_FILTER_FIELDS } from '../../graphql/fragments/user/filters';

export const AFTER_TOKEN_CHECK = gql`
${USER_ACCOUNTS_FILTER_FIELDS}
${SELECTED_USER_FILTERS}

${ACCOUNTS_SETTING_FILTER_FIELDS}

query callLog{
  afterTokenCheck{
  token
  _id
  title
  lastName
  firstName
  email
  role
  accounts{
    _id
    _db
    name
    settings{
      ...AccountSettingFieldsHOC
    }
  }
  phone1
  phone2
  profileImage
  beta
  selectedFilters{
    ...SelectedFiltersFields
  }
  settings{
    favorites{
      facility
    }
  }
}
}
`;
