import Methods from '../../lib/Methods'
import { SELECTED_USER_FILTERS } from '../../graphql/fragments/user/filters'
import { gql, DPGraphQLQueryHOC } from '../../graphql';
import { ACCOUNTS_SETTING_FILTER_FIELDS } from '../../graphql/fragments/user/accountsetting';

export const GET_CURRENT_USERS = gql`
  ${SELECTED_USER_FILTERS}
  ${ACCOUNTS_SETTING_FILTER_FIELDS}

  query getCurrentUser {
    currentUser {
      _id
      title
      firstName
      lastName
      email
      phone1
      phone2
      accountsSettings {
        accountId {
          name
          _id
          _db
        }
        availableHours
      }
      role
      beta
      accounts {
        _id
        _db
        name
        settings{
        ...AccountSettingFieldsHOC
      }
      }
      profileImage
      dashboardSetting
      dashboardResponsible {
        _id
        email
        profileImage
        firstName
        lastName
      }
      selectedAccount
      settings {
        pipeline {
          showStatistics
        }
        favorites {
          facility
        }
        notification {
          dailySummary {
            email
            sms
            ui
          }
          commentInProject {
            email
            sms
            ui
          }
          commentInTask {
            email
            sms
            ui
          }
          updateInOrder {
            email
            sms
            ui
          }
          createOrder {
            email
            sms
            ui
          }
          projectAssign {
            email
            sms
            ui
          }
          taskAssign {
            email
            sms
            ui
          }
          taskComplete {
            email
            sms
            ui
          }
          addedInMeeting {
            email
            sms
            ui
          }
          watcher {
            email
            sms
            ui
          }
        }
      }
      selectedFilters {
        ...SelectedFiltersFields
      }
      starProjects {
        projectId
        accountId
      }
    }
  }
`;

const withCurrentUser = Component => props => (
  <DPGraphQLQueryHOC query={GET_CURRENT_USERS}>
    {({ loading, data, error }) =>
    {

      let errorTosend
      if (error)
      {

        errorTosend = error.networkError.result

      }
      if (data && data.currentUser)
      {

        const currentUser = Methods.getLocalStorageUser()
        if (currentUser?.filters)
        {

          currentUser.filters.recentAccounts = (data.currentUser?.filters?.recentAccounts || [])
          currentUser.filters.recentUsers = (data.currentUser?.filters?.recentUsers || [])
          global.localStorage.setItem('userInfo', JSON.stringify(currentUser));

        }

      }
      return (
        <Component
          loading={loading}
          apolloError={errorTosend}
          currentUser={data && data.currentUser}
          {...props}
        />
      )

    }}
  </DPGraphQLQueryHOC>
)

export default withCurrentUser
