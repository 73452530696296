import antdData from 'antd/lib/locale-provider/sv_SE'
import messages from '@dp-common/locale/translations/sw-SW.json'
import DPPartsSWLocale from '@dp-parts/locales/translations/sw-SW.json'
import DPUISWLocale from '@dp-ui/locales/translations/sw-SW.json'
import DPUI2SWLocale from '@dp-ui2/locales/translations/_autogenerated/sv-SE.json'


export default {
  locale: 'sv-SE',
  antdData,
  messages: {
    ...DPUI2SWLocale, ...messages, ...DPPartsSWLocale, ...DPUISWLocale,
  },
}
