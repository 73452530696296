import antdData from 'antd/lib/locale-provider/en_US'
import messages from '@dp-common/locale/translations/en-US.json'
import DPPartsEnLocale from '@dp-parts/locales/translations/en-US.json'
import DPUIEnLocale from '@dp-ui/locales/translations/en-US.json'
import DPUI2EnLocale from '@dp-ui2/locales/translations/_autogenerated/en-US.json'

export default {
  locale: 'en-US',
  antdData,
  messages: {
    ...DPUI2EnLocale, ...DPUIEnLocale, ...messages, ...DPPartsEnLocale,
  },
}
